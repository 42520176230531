import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  AuthInterface,
  DecodedTokenInterrrface,
  TokenInterface,
} from '../interfaces/auth.interface';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private httpClient: HttpClient) {}

  login(data: AuthInterface) {
    const companyId = sessionStorage.getItem('user_id');

    const url = environment.url + `/open/register/${companyId}/signup`;
    return this.httpClient.post<AuthInterface>(url, data);
  }

  createToken(hash: string) {
    const url = environment.url + `/open/login/hash/${hash}`;
    return this.httpClient.post<TokenInterface>(url, {}).pipe(
      tap((response) => {
        this.saveToken(response);
        return response;
      })
    );
  }

  getUserIdByHash(hash: string) {
    return this.httpClient.get(
      `${environment.url}/secure-api/company/hash/${hash}`,
      {
        headers: {
          'Content-Type': 'plain/text',
        },
        responseType: 'text', // Indica que a resposta deve ser tratada como texto
      }
    );
  }

  saveToken(token: TokenInterface) {
    sessionStorage.setItem('token', token.token);
  }

  getDecodedToken() {
    const token = sessionStorage.getItem('token');
    if (!token) return null;

    const tokenDecoded = JSON.parse(atob(token.split('.')[1]));
    return tokenDecoded as DecodedTokenInterrrface;
  }
}
