<div class="img">
  <div class="page">
    <div class="informations-container">
      <div class="content-container">
        <div class="title-container">
          <h1 data-cy="welcome-message-h1">Bem-vindo a Broadfactor!</h1>
        </div>
        <div class="description">
          <p class="text">
            Somos uma plataforma digital de antecipação de recebíveis que nasceu
            com o propósito de levar prosperidade aos empreendedores, ajudamos a
            transformar sonhos em negócios de sucesso.
          </p>
          <br />
          <p class="small-text">
            Confira se a sua empresa está apta para antecipar recebíveis:
          </p>
        </div>
        <div class="requirements">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_304_1032)">
              <path
                d="M18 17H6V15H18V17ZM18 13H6V11H18V13ZM18 9H6V7H18V9ZM3 22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.5 20.5L15 22L16.5 20.5L18 22L19.5 20.5L21 22V2L19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22Z"
                fill="#1E1E1E"
              />
            </g>
            <defs>
              <clipPath id="clip0_304_1032">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>Vende para Pessoa Jurídica</p>
        </div>

        <div class="requirements">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 14V6C19 4.9 18.1 4 17 4H3C1.9 4 1 4.9 1 6V14C1 15.1 1.9 16 3 16H17C18.1 16 19 15.1 19 14ZM10 13C8.34 13 7 11.66 7 10C7 8.34 8.34 7 10 7C11.66 7 13 8.34 13 10C13 11.66 11.66 13 10 13ZM23 7V18C23 19.1 22.1 20 21 20H4V18H21V7H23Z"
              fill="#1E1E1E"
            />
          </svg>
          <p>Faturamento mínimo de 100 mil mensais</p>
        </div>

        <div class="requirements">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_363_924)">
              <path
                d="M17 12H12V17H17V12ZM16 1V3H8V1H6V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H18V1H16ZM19 19H5V8H19V19Z"
                fill="#1E1E1E"
              />
            </g>
            <defs>
              <clipPath id="clip0_363_924">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <p>Possui CNPJ há 1 ano ou mais</p>
        </div>
        <span class="privacy-policy-text"
          >Ao continuar você concorda com a nossa
          <a
            data-cy="privacy-policy-link"
            href="https://broadfactors3-public.s3.sa-east-1.amazonaws.com/Termos/cedente/Pol%C3%ADtica+de+Privacidade+Broadfactor.pdf"
            target="_blank"
            >Política de Privacidade</a
          ></span
        >
        <br />
        <button
          mat-flat-button
          class="button-next"
          color="accent"
          (click)="navigatePreRegister()"
          data-cy="navigate-preregister-button"
        >
          Continuar
        </button>
      </div>
    </div>

    <img
      class="mobile-image"
      src="https://broadfactor-lite-qa.s3.amazonaws.com/Pre-cadastro.png"
      alt="foguete decolando"
    />
  </div>
</div>
