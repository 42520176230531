<div class="blankslate-page">
  <div class="content">
    <div class="text">
      <h1>Cadastro finalizado com sucesso!</h1>

      <p>Falta pouco para começar a antecipar!</p>

      <p>
        Para acessar a plataforma quando seu acesso estiver liberado, basta
        clicar no botão abaixo.
      </p>
      <button
        mat-flat-button
        color="accent"
        class="mt-3 py-3"
        (click)="goToPortal()"
      >
        IR PARA PLATAFORMA
      </button>
    </div>
    <img
      src="../../../../assets/svg/Tela 11 - Aprovado.svg"
      width="600px"
      height="auto"
      alt="Formulário"
    />
  </div>
</div>
