<div class="auth-container">
  <ng-container *ngIf="!showFinishPage">
    <form class="w-100" [formGroup]="formRegister">
      <p class="subtitle mt-0">Credenciais de Acesso à Plataforma</p>

      <div class="form-content">
        <mat-form-field appearance="outline" class="input-outline">
          <mat-label>E-mail</mat-label>
          <input
            matInput
            placeholder="exemplo@exemplo"
            required
            formControlName="mail"
            name="email"
            type="email"
          />
          <mat-error *ngIf="formRegister.get('mail')?.hasError('required')">
            O campo e-mail é obrigatório.
          </mat-error>
          <mat-error *ngIf="formRegister.get('mail')?.hasError('email')">
            O e-mail inserido não é válido.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-outline">
          <mat-label>Crie sua Senha</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            required
            autocomplete="on"
            data-cy="password-input"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-hint>Mínimo 8 dígitos</mat-hint>
          <mat-error *ngIf="formRegister.get('password')?.hasError('required')">
            O campo senha é obrigatório.
          </mat-error>
          <mat-error
            *ngIf="formRegister.get('password')?.hasError('minlength')"
          >
            A senha deve ter no mínimo 8 caracteres.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-outline">
          <mat-label>Confirmar sua senha</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="confirmPassword"
            required
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hideConfirmPassword = !hideConfirmPassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hideConfirmPassword"
          >
            <mat-icon>{{
              hideConfirmPassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <mat-error
            *ngIf="
              formRegister.get('confirmPassword')?.hasError('passwordsMismatch')
            "
          >
            As senhas não coincidem
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <div class="register-button">
      <button
        color="accent"
        mat-flat-button
        (click)="login()"
        [disabled]="formRegister.invalid"
      >
        Cadastrar
      </button>
    </div>
    <div class="tooltp">
      <mat-icon
        matTooltip="O e-mail e a senha cadastrados serão utilizados para acessar a plataforma. Mantenha suas credenciais em sigilo para garantir a segurança da sua conta."
        >help
      </mat-icon>
    </div>

    <div class="actions">
      <div *ngIf="hasError" class="alert alert-danger" role="alert">
        Ops! Ocorreu um erro ao tentar cadastrar os seus dados, tente novamente.
      </div>
      <div
        *ngIf="savingData"
        class="alert alert-warning d-flex justify-content-between"
        role="alert"
      >
        Cadastrando... <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <div *ngIf="isSave" class="alert alert-success" role="alert">
        Informações cadastradas com sucesso!
      </div>
    </div>
  </ng-container>
  <app-finish-page *ngIf="showFinishPage"></app-finish-page>
</div>
