import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-finish-page',
  templateUrl: './finish-page.component.html',
  styleUrls: ['./finish-page.component.scss'],
})
export class FinishPageComponent implements OnInit {
  @Output() back = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  goToPortal() {
    window.open('https://plataforma.broadfactor.com/Broadfactor', '_blank');
  }
}
